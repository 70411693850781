
/* Start of menu CSS */

/* .sidebarText:hover, .sidebarTextHome:hover, .sidebarTextContact:hover {
  color: #6E6E6E;
  darkthemecolor
} */

.sidebarText{
  padding-left: 6vw;
  padding-right: 1vw;
  /* darkthemecolor */
}

.sidebarTextHome {
  padding-left: 15vw;
  padding-right: 2vw;
  /* darkthemecolor */
}

.sidebarTextContact {
  padding-left: 5vw;
  padding-right: 1vw;
  /* darkthemecolor */
}

/* HERE'S HOW THE MENU WORKS: */
/* The div is always there, and there's no transformation when the menu is open. */
#menu.open {
  transform: none;
}

#menu.closed{
  visibility: hidden;
}

/* But when the menu is closed, the div is still /displayed/ */
/* It's just that it's translated 10vh off of the screen (which is the height of the menu div) */
#menu {
  width: 100vw;
  height: 10vh;
  /* darkthemecolor */
  align-items: center;
  flex-direction: row;
  display: flex;
  /* justify-content: space-between; */
  font-size: 1.8vw;
  transition: transform 0.2s ease-out;
  transform: translateY(-10vh); /*This is what hides the menu when it's closed*/
  position: fixed;
  z-index: 99;
  /* darkthemecolor */
}

button{
  outline:none;
  position: fixed;
  z-index: 100;
}

#hamburger {
  z-index: 100;
  margin-left: .8vw;
  margin-top: 1.5vh;
}

#dark-switch{
  position: fixed;
  z-index: 100;
  margin-top: 3vh;
  right: 0.8vw;
}

/* End of menu CSS */
