.projectsPageWrapper {
  height: 100vh;
  /* background:
  radial-gradient(circle at 5vw 0, #FFB9CD,
    #FFB9CD 35vw, transparent 35vw); */
  /* darkthemecolor */
}

.projectsPageWrapper h1 {
  /* color: #008F82; */
  /* darkthemecolor */
  font-size: calc(20px + 1vw + 0.3vh);
  font-weight: bold;
}

.projectText {
  color: white; 
  /* ^ doesnt change between themes */
  /* darkthemecolor */
  font-size: calc(14px + 0.7vw + 0.2vh);
  width: calc(30px + 13vh + 3vw);
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.circle {
  /* background: #F1F1F1; */
  /* darkthemecolor */
  border-radius: 50%;
  height: calc(40px + 15vh + 6.5vw);
  width: calc(40px + 15vh + 6.5vw);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  transition: background 0.5s ease-in-out;
}

.circle:hover {
  /* background: rgba(64, 191, 180, 0.8); */
  /* darkthemecolor */
}

.circle:hover .projectText{
  opacity: 1;
}

.circle .airbnb {
  position: absolute;
  height: calc(14px + 7vh + 14vw);
  opacity: 0.5;
  transition: 0.5s ease-in-out;
}

.circle:hover .airbnb {
  opacity: 0;
}

.projectTitles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ppTitle {
  margin-left: 50px;
  margin-top: 0px;
}

.fpTitle {
  margin-right: 50px;
  margin-top: 0px;
}


#beakspeak {
  margin-top: 25vh;
  margin-left: 5vw;
  height: 35vh;
  width: 35vh;
  position: absolute;
}

#BsTitle {
  margin-top: calc(9px + 4vh + 7.5vw);
  margin-left: calc(9px + 2.5vh + 4vw);
  position: absolute;
  z-index: 2;
}

#carpool {
  margin-top: 5vh;
  margin-left: 25vw;
  height: 35vh;
  width: 35vh;
  position: absolute;
}

#cTitle {
  margin-top: 0vh;
  margin-left: calc(7px + 1vh + 2vw);
  width: calc(6px + 8vh + 14vw);
  position: absolute;
  z-index: 2;
}

#website {
  margin-top: 50vh;
  margin-left: 25vw;
  height: 35vh;
  width: 35vh;
  text-align: right;
  position: absolute;
}

#wTitle {
  margin-top: calc(4px + 4vh + 7.5vw);
  margin-left: -5vw;
  width: 15vw;
  position: absolute;
  z-index: 2;
}

#pdi {
  margin-top: 5vh;
  margin-left: 60vw;
  height: 35vh;
  width: 35vh;
  position: absolute;
}

#pdiTitle {
  margin-top: 0vh;
  margin-left: calc(7px + 1vh + 2vw);
  width: calc(6px + 8vh + 14vw);
  position: absolute;
  z-index: 2;
}

#hedwig {
  margin-top: 25vh;
  margin-left: 80vw;
  height: 35vh;
  width: 35vh;
  text-align: right;
  position: absolute;
}

#HwTitle {
  margin-top: calc(4px + 6vh + 7.5vw);
  margin-left: -5vw;
  width: 15vw;
  position: absolute;
  z-index: 2;
}

#ccd {
  margin-top: 50vh;
  margin-left: 60vw;
  height: 35vh;
  width: 35vh;
  position: absolute;
}

#RTitle {
  margin-top: 0vh;
  margin-left: 0vw;
  position: absolute;
  z-index: 2;
}

.link {
  text-decoration: none;
}

/* .link:hover {
  text-decoration: underline;
  text-decoration-color: #008F82;
  darkthemecolor
} */

@media only screen and (max-width:1200px) {
  .projectsPageWrapper h1 {
    /* color: #008F82; */
    /* darkthemecolor */
    font-size: calc(13px + 2vw + 0.3vh);
    font-weight: bold;
  }

  .projectText {
    color: white;
    font-size: calc(12px + 1.5vw + 0.15vh);
    width: calc(15px + 17vw + 4vh);
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .circle {
    /* background: #F1F1F1; */
    /* darkthemecolor */
    border-radius: 50%;
    height: calc(35px + 10vw + 4vh);
    width: calc(35px + 10vw + 4vh);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    transition: background 0.5s ease-in-out;
  }

  #beakspeak {
    margin-top: 10vh;
    margin-left: 5vw;
    height: 10vw;
    width: 10vw;
    position: absolute;
  }
  
  #BsTitle {
    margin-top: calc(9px + 4vh + 7.5vw);
    margin-left: calc(9px + 2.5vh + 4vw);
    position: absolute;
    z-index: 2;
  }
  
  #carpool {
    margin-top: 30vh;
    margin-left: 25vw;
    height: 10vw;
    width: 10vw;
    position: absolute;
  }
  
  #cTitle {
    margin-top: 0vh;
    margin-left: calc(7px + 1vh + 2vw);
    width: calc(6px + 8vh + 14vw);
    position: absolute;
    z-index: 2;
  }
  
  #website {
    margin-top: 50vh;
    margin-left: 5vw;
    height: 10vw;
    width: 10vw;
    text-align: right;
    position: absolute;
  }
  
  #wTitle {
    margin-top: calc(4px + 4vh + 7.5vw);
    margin-left: -5vw;
    width: 15vw;
    position: absolute;
    z-index: 2;
  }
  
  #pdi {
    margin-top: 30vh;
    margin-left: 55vw;
    height: 35vh;
    width: 40vh;
    position: absolute;
  }
  
  #pdiTitle {
    margin-top: 0vh;
    margin-left: calc(7px + 1vh + 2vw);
    width: calc(6px + 8vh + 14vw);
    position: absolute;
    z-index: 2;
  }
  
  #hedwig {
    margin-top: 10vh;
    margin-left: 75vw;
    height: 35vh;
    width: 35vh;
    text-align: right;
    position: absolute;
  }
  
  #HwTitle {
    margin-top: calc(4px + 6vh + 7.5vw);
    margin-left: -5vw;
    width: 15vw;
    position: absolute;
    z-index: 2;
  }
  
  #ccd {
    margin-top: 50vh;
    margin-left: 75vw;
    height: 35vh;
    width: 35vh;
    position: absolute;
  }
  
  #RTitle {
    margin-top: 0vh;
    margin-left: 0vw;
    position: absolute;
    z-index: 2;
  }
}

@media only screen and (max-width:749px) {
  .projectsPageWrapper h1 {
    /* color: #008F82; */
    /* darkthemecolor */
    font-size: calc(10px + 1.7vw + 0.3vh);
    font-weight: bold;
  }

  .projectText {
    color: white;
    /* darkthemecolor */
    font-size: calc(10px + 0.7vw + 0.15vh);
    width: calc(22px + 7vh + 3vw);
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .circle {
    /* background: #F1F1F1; */
    /* darkthemecolor */
    border-radius: 50%;
    height: calc(35px + 10vw + 4vh);
    width: calc(35px + 10vw + 4vh);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    transition: background 0.5s ease-in-out;
  }

  #beakspeak {
    margin-top: 15vh;
    margin-left: 5vw;
    height: 15vh;
    width: 15vh;
    position: absolute;
  }

  #carpool {
    margin-top: 35vh;
    margin-left: 5vw;
    height: 15vh;
    width: 15vh;
    text-align: right;
    position: absolute;
  }

  #website {
    margin-top: 55vh;
    margin-left: 5vw;
    height: 15vh;
    width: 15vh;
    text-align: center;
    position: absolute;
  }

  #wTitle {
    margin-top: -5px;
    text-align: center;
  }

  #hedwig {
    margin-top: 5vh;
    margin-left: 50vw;
    height: 35vh;
    width: 40vh;
    position: absolute;
  }

  #HwTitle {
    margin-top: -1vh;
    margin-left: calc(0.5vh + 1vw);
    width: calc(30px + 20vw + 4vh);
    position: absolute;
    z-index: 2;
  }

  #pdi {
    margin-top: 25vh;
    margin-left: 50vw;
    height: 35vh;
    width: 40vh;
    position: absolute;
  }

  #ccd {
    margin-top: 45vh;
    margin-left: 50vw;
    height: 35vh;
    width: 40vh;
    position: absolute;
  }
}
