.whatWeDoPageWrapper{
  height: 70vh;
  /* z-index: 1; */
  /* background: white; */
  /* darkthemecolor */
}

.whatWeDoPageWrapper h1{
  width: 80vw;
  color: white;
  font-weight: bold;
  font-size: calc(3px + 1.9vw + 0.5vh);
}

#treeBackground {
  height: 80vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),/* the gradient on top, adjust color and opacity to your taste */
url('../images/RiceApps2022.jpg');


  display: flex;
  align-items: center;
  justify-content: center;

  z-index: -99;

  background-size: 100%;
}


@media only screen and (max-width:1680px) {
  #treeBackground {
    background-attachment: inherit;
    height: 80vh;
  }
}

@media only screen and (max-width:1200px) {
  .whatWeDoPageWrapper{
    height: 55vh;
    /* z-index: 1; */
    /* background: white; */    
    /* darkthemecolor */
  }

  #treeBackground {
    background-attachment: inherit;
    height: 80vh;
  }

  .whatWeDoPageWrapper h1{
    width: 85vw;
    color: white;
    /* darkthemecolor */
    font-weight: bold;
    font-size: calc(5px + 2.3vw + 0.9vh);
  }

}

@media only screen and (max-width:749px) {
  .whatWeDoPageWrapper{
    height: 45vh;
    /* z-index: 1; */
    /* background: white; */
    /* darkthemecolor */
  }

  #treeBackground {
    background-attachment: inherit;
    background-size: 110%;
    height: 35vh;
  }

  .whatWeDoPageWrapper h1{
    width: 90vw;
    color: white;
    font-weight: bold;
    font-size: calc(5px + 2vw + 0.7vh);
  }

}
