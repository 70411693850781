.AboutUsWrapper {
  height:100vh;
  /* background: white; */
  /* darkthemecolor */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background:
  radial-gradient(circle at 5vw 100vh, #FFB9CD,
    #FFB9CD 35vw, transparent 35vw); */
  /* darkthemecolor */
  z-index: 1;
}

#AboutUsParagraph {
  width: 65vw;
  height: 50vh;
  font-family: 'HKGrotesk-Bold-Legacy';
  font-weight: bold;
  font-size: calc(3px + 2.2vw + 0.6vh);
  line-height: 6.3vh;
  letter-spacing: 0.08vw;
  /* color: #787878; */
  /* darkthemecolor */
}
#AboutUsParagraph em {
  font-style: normal;
  /* color: #40BFB4; */
  /* darkthemecolor */
}

Reveal {
  animation-name: fadeInUp;
  animation-duration: 1s;
}

@media only screen and (max-width:1200px) {
  #AboutUsParagraph {
    width: 70vw;
    height: 50vh;
    font-family: 'HKGrotesk-Bold-Legacy';
    font-weight: bold;
    font-size: calc(6px + 2.8vw + 0.7vh);
    line-height: 6.3vh;
    letter-spacing: 0.08vw;
    /* color: #787878; */
    /* darkthemecolor */
  }
}

@media only screen and (max-width:749px) {
  #AboutUsParagraph {
    width: 75vw;
    height: 50vh;
    font-family: 'HKGrotesk-Bold-Legacy';
    font-weight: bold;
    font-size: calc(6px + 2.8vw + 0.7vh);
    line-height: 6.3vh;
    letter-spacing: 0.08vw;
    /* color: #787878; */
    /* darkthemecolor */
  }
}
