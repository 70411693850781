body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  overflow-x: hidden;
}

#riceApps{
  position: absolute;
  -webkit-animation-name: appear;
          animation-name: appear;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;
  font-size: 7.5vw;
  font-family: 'HKGrotesk-Light';
  letter-spacing: 0.2vw;
  /* color: gray; */
  /* darkthemecolor */
}

img{
  width: 28vw;
}

.arrow-open{
  display: flex;
  margin-top: 40vh;

  justify-content: center;
  /* display: inline-block; */
  width: 50%;
  -webkit-animation-name: appear;
          animation-name: appear;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;

  height: 50px;
}
.arrow-closed{
  display: flex;
  justify-content: center;
  visibility: hidden;
  width: 50%;
  margin: 0 auto;
  height: 50px;
}
#arrow{
  cursor: pointer;
}

@-webkit-keyframes appear {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes appear {
    from {opacity: 0;}
    to {opacity: 1;}
}

#title {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 100vh;
  /* background-color: white; */
  /* darkthemecolor */
}

.initialText {
  position: absolute;
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 1;
}

@-webkit-keyframes fade {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes fade {
    from {opacity: 1;}
    to {opacity: 0;}
}

#ds{
  display: flex;
  /* color: #40BFB4; */
  /* darkthemecolor */
  width: 100vw;
  align-self: flex-end;
  justify-content: center;
  font-size: 7.5vw;
  font-family: 'HKGrotesk-Regular';
  letter-spacing: 0.2vw;
  font-weight: 50;
}

#gray {
  /* color: gray !important; */
  /* darkthemecolor */
  padding-right: 4vw;
}

#sg {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  font-size: 7.5vw;
  font-family: 'HKGrotesk-Bold';
  letter-spacing: 0.2vw;
  font-weight: bold;
  /* color: #40BFB4; */
  /* darkthemecolor */
}

#social {
  padding-right: 4vw;
}

i {
  /* border: solid black; */
  /* darkthemecolor */
  /* border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px; */
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#arrow {
  position: relative;
  margin-top: 10vw;
}

.TestmonialsWrapper {
  height:100vh;
  /* background: white; */
  /* darkthemecolor */
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.CarouselWrapper {
  height:80vh;
  margin-left: 10vw;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.paragraphWrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.carouselBox {
  width: 80vw;
  height: calc(40px + 45vh + 5vw);
}

.TestimonialParagraph {
  position: relative;
  width: 70vw;
  /* height: 16vw; */
  font-family: 'Questrial', sans-serif;
  font-weight: 400;
  font-size: calc(8px + 1.6vw + 0.5vh);
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  letter-spacing: 0.15vw;
  /* color: #787878; */
  /* darkthemecolor */
  margin-top: 4.5vh;
  text-align: left;
}
.TestimonialParagraph em {
  font-style: normal;
  /* color: #40BFB4; */
  /* darkthemecolor */
}

.testimonialName {
  font-weight: bold;
}


#teamLead{
  margin-left: 1.8vw;
  margin-top: 0.8vh;
}

.nameWrapper{
  position: relative;
  display: flex;
  flex-direction: column;
  /* color: #01796F; */
  /* darkthemecolor */
  font-size: calc(5px + 1.2vw + 0.35vh);
  margin-top: 3vh;
  font-family: sans-serif;
  /* align-items: flex-start;
  justify-content: flex-start; */
}

#content-WorkForUs{
  height: 120vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* darkthemecolor
  background:
  radial-gradient(circle at 20vw 90vh, #40BFB4, #40BFB4 35vw, transparent 35vw),
  radial-gradient(circle at 77vw -10vw, #FFB9CD,
    #FFB9CD 30vw, transparent 30vw); */
}

#JoinUsTitle{
  margin-left: 7vw;
  display: flex;
  align-self: flex-start;
  width: 100vw;
  font-size: calc(40px + 5vh + 1.7vw);
  /* color: #FFB9CD; */
  /* darkthemecolor */
  font-weight: 1000;
  letter-spacing: 0.3vw;
  font-family: 'HKGrotesk-Bold';
  cursor: pointer;
}



#JoinUsText{
  padding-top: 28vh;
  margin-left: 7vw;
  display: flex;
  align-self: flex-end;
  font-weight: bold;
  letter-spacing: 0.12vw;
  /* color: white; */
  /* darkthemecolor */
  font-size: calc(5px + 1.6vh + 0.7vw);
  font-family: 'HKGrotesk-Bold';
}

#display{
  display: flex;
  width: 100vw;
  align-self: center;
}

@media screen and (max-width: 1024px) {
  #content-WorkForUs{

    height: 120vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* darkthemecolor */
    /* background:
            radial-gradient(circle at 20vw 90vh, #40BFB4, #40BFB4 45vw, transparent 45vw),
            radial-gradient(circle at 77vw -10vw, #FFB9CD,
            #FFB9CD 40vw, transparent 40vw); */
  }

  #JoinUsTitle{
    margin-left: 7vw;
    display: flex;
    align-self: flex-start;
    width: 100vw;
    font-size: calc(10px + 7vw + 1.3vh);
    /* color: #FFB9CD; */
    /* darkthemecolor */
    font-weight: 1000;
    letter-spacing: 0.3vw;
    font-family: 'HKGrotesk-Bold';
    cursor: pointer;
  }

  #JoinUsText{
    padding-top: 50vh;
    margin-left: 7vw;
    display: flex;
    align-self: flex-end;
    font-weight: bold;
    letter-spacing: 0.12vw;
    /* color: white; */
    /* darkthemecolor */
    font-size: calc(5px + 1.5vh + 0.8vw);
    font-family: 'HKGrotesk-Bold';
  }



  #display{
    display: none;
  }
}

@media screen and (max-width: 749px) {
  #JoinUsTitle{
    margin-left: 5vw;
    display: flex;
    align-self: flex-start;
    width: 100vw;
    font-size: calc(10px + 7vw + 1.3vh);
    /* color: #FFB9CD; */
    /* darkthemecolor */
    font-weight: 1000;
    letter-spacing: 0.3vw;
    font-family: 'HKGrotesk-Bold';
    cursor: pointer;
  }
  #JoinUsText{
    padding-top: 50vh;
    margin-left: 5vw;
    display: flex;
    align-self: flex-end;
    font-weight: bold;
    letter-spacing: 0.12vw;
    /* color: white; */
    /* darkthemecolor */
    font-size: calc(3px + 1.5vh + 0.6vw);
    font-family: 'HKGrotesk-Bold';
  }
}

#content-JoinUs{
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: white; */
  /* darkthemecolor */
  /* background:
  radial-gradient(circle at 77vw calc(100vh - 10vw), #FFB9CD,
    #FFB9CD 30vw, transparent 30vw); */
    /* darkthemecolor */
}

#ContactUsTitle{
  margin-top: 20vh;
  margin-left: 7vw;
  display: flex;
  align-self: flex-end;
  width: 100vw;
  font-size: calc(40px + 5vh + 1.7vw);
  /* color: #40BFB4; */
  /* darkthemecolor */
  font-weight: 1000;
  letter-spacing: 0.3vw;
  font-family: 'HKGrotesk-Bold';
  cursor: pointer;
}


#ContactUsText{
  padding-top: 2vh;
  margin-left: 7vw;
  margin-top: -2.5vh;
  display: flex;
  align-self: flex-start;
  font-weight: bold;
  letter-spacing: 0.12vw;
  /* color: #787878; */
  /* darkthemecolor */
  font-size: calc(5px + 1.6vh + 0.7vw);
  font-family: 'HKGrotesk-Bold';
  margin-bottom: -50vh;
}

#display{
  display: flex;
  width: 100vw;
  align-self: center;
}

@media screen and (max-width: 1024px) {
  #content-JoinUs{
    margin-top: -75vh;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: white; */
    /* darkthemecolor */
    /* background:
      radial-gradient(circle at 77vw calc(100vh - 10vw), #FFB9CD,
      #FFB9CD 40vw, transparent 40vw); */
    /* darkthemecolor */
  }

  #ContactUsTitle{
    margin-top: 70vh;
    margin-left: 7vw;
    display: flex;
    align-self: flex-end;
    width: 100vw;
    font-size: calc(10px + 7vw + 1.3vh);
    /* color: #40BFB4; */
    /* darkthemecolor */
    font-weight: 1000;
    letter-spacing: 0.3vw;
    font-family: 'HKGrotesk-Bold';
    cursor: pointer;
  }

  #ContactUsText{
    padding-top: 2vh;
    margin-left: 7vw;
    margin-top: -2.5vh;
    display: flex;
    align-self: flex-start;
    font-weight: bold;
    letter-spacing: 0.12vw;
    /* color: #787878; */
    /* darkthemecolor */
    font-size: calc(5px + 1.5vh + 0.8vw);
    font-family: 'HKGrotesk-Bold';
    margin-bottom: -50vh;
  }


  #display{
    display: none;
  }
}

@media screen and (max-width: 749px) {
  #ContactUsTitle{
    margin-top: 70vh;
    margin-left: 5vw;
    display: flex;
    align-self: flex-end;
    width: 100vw;
    font-size: calc(10px + 7vw + 1.3vh);
    /* color: #40BFB4; */
    /* darkthemecolor */
    font-weight: 1000;
    letter-spacing: 0.3vw;
    font-family: 'HKGrotesk-Bold';
    cursor: pointer;
  }
  #ContactUsText{

    padding-top: 2vh;
    margin-left: 5vw;
    margin-top: -2.5vh;
    display: flex;
    align-self: flex-start;
    font-weight: bold;
    letter-spacing: 0.12vw;
    /* color: #787878; */
    /* darkthemecolor */
    font-size: calc(3px + 1.5vh + 0.6vw);
    font-family: 'HKGrotesk-Bold';
    margin-bottom: -50vh;
  }
}

.projectsPageWrapper {
  height: 100vh;
  /* background:
  radial-gradient(circle at 5vw 0, #FFB9CD,
    #FFB9CD 35vw, transparent 35vw); */
  /* darkthemecolor */
}

.projectsPageWrapper h1 {
  /* color: #008F82; */
  /* darkthemecolor */
  font-size: calc(20px + 1vw + 0.3vh);
  font-weight: bold;
}

.projectText {
  color: white; 
  /* ^ doesnt change between themes */
  /* darkthemecolor */
  font-size: calc(14px + 0.7vw + 0.2vh);
  width: calc(30px + 13vh + 3vw);
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.circle {
  /* background: #F1F1F1; */
  /* darkthemecolor */
  border-radius: 50%;
  height: calc(40px + 15vh + 6.5vw);
  width: calc(40px + 15vh + 6.5vw);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  transition: background 0.5s ease-in-out;
}

.circle:hover {
  /* background: rgba(64, 191, 180, 0.8); */
  /* darkthemecolor */
}

.circle:hover .projectText{
  opacity: 1;
}

.circle .airbnb {
  position: absolute;
  height: calc(14px + 7vh + 14vw);
  opacity: 0.5;
  transition: 0.5s ease-in-out;
}

.circle:hover .airbnb {
  opacity: 0;
}

.projectTitles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ppTitle {
  margin-left: 50px;
  margin-top: 0px;
}

.fpTitle {
  margin-right: 50px;
  margin-top: 0px;
}


#beakspeak {
  margin-top: 25vh;
  margin-left: 5vw;
  height: 35vh;
  width: 35vh;
  position: absolute;
}

#BsTitle {
  margin-top: calc(9px + 4vh + 7.5vw);
  margin-left: calc(9px + 2.5vh + 4vw);
  position: absolute;
  z-index: 2;
}

#carpool {
  margin-top: 5vh;
  margin-left: 25vw;
  height: 35vh;
  width: 35vh;
  position: absolute;
}

#cTitle {
  margin-top: 0vh;
  margin-left: calc(7px + 1vh + 2vw);
  width: calc(6px + 8vh + 14vw);
  position: absolute;
  z-index: 2;
}

#website {
  margin-top: 50vh;
  margin-left: 25vw;
  height: 35vh;
  width: 35vh;
  text-align: right;
  position: absolute;
}

#wTitle {
  margin-top: calc(4px + 4vh + 7.5vw);
  margin-left: -5vw;
  width: 15vw;
  position: absolute;
  z-index: 2;
}

#pdi {
  margin-top: 5vh;
  margin-left: 60vw;
  height: 35vh;
  width: 35vh;
  position: absolute;
}

#pdiTitle {
  margin-top: 0vh;
  margin-left: calc(7px + 1vh + 2vw);
  width: calc(6px + 8vh + 14vw);
  position: absolute;
  z-index: 2;
}

#hedwig {
  margin-top: 25vh;
  margin-left: 80vw;
  height: 35vh;
  width: 35vh;
  text-align: right;
  position: absolute;
}

#HwTitle {
  margin-top: calc(4px + 6vh + 7.5vw);
  margin-left: -5vw;
  width: 15vw;
  position: absolute;
  z-index: 2;
}

#ccd {
  margin-top: 50vh;
  margin-left: 60vw;
  height: 35vh;
  width: 35vh;
  position: absolute;
}

#RTitle {
  margin-top: 0vh;
  margin-left: 0vw;
  position: absolute;
  z-index: 2;
}

.link {
  text-decoration: none;
}

/* .link:hover {
  text-decoration: underline;
  text-decoration-color: #008F82;
  darkthemecolor
} */

@media only screen and (max-width:1200px) {
  .projectsPageWrapper h1 {
    /* color: #008F82; */
    /* darkthemecolor */
    font-size: calc(13px + 2vw + 0.3vh);
    font-weight: bold;
  }

  .projectText {
    color: white;
    font-size: calc(12px + 1.5vw + 0.15vh);
    width: calc(15px + 17vw + 4vh);
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .circle {
    /* background: #F1F1F1; */
    /* darkthemecolor */
    border-radius: 50%;
    height: calc(35px + 10vw + 4vh);
    width: calc(35px + 10vw + 4vh);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    transition: background 0.5s ease-in-out;
  }

  #beakspeak {
    margin-top: 10vh;
    margin-left: 5vw;
    height: 10vw;
    width: 10vw;
    position: absolute;
  }
  
  #BsTitle {
    margin-top: calc(9px + 4vh + 7.5vw);
    margin-left: calc(9px + 2.5vh + 4vw);
    position: absolute;
    z-index: 2;
  }
  
  #carpool {
    margin-top: 30vh;
    margin-left: 25vw;
    height: 10vw;
    width: 10vw;
    position: absolute;
  }
  
  #cTitle {
    margin-top: 0vh;
    margin-left: calc(7px + 1vh + 2vw);
    width: calc(6px + 8vh + 14vw);
    position: absolute;
    z-index: 2;
  }
  
  #website {
    margin-top: 50vh;
    margin-left: 5vw;
    height: 10vw;
    width: 10vw;
    text-align: right;
    position: absolute;
  }
  
  #wTitle {
    margin-top: calc(4px + 4vh + 7.5vw);
    margin-left: -5vw;
    width: 15vw;
    position: absolute;
    z-index: 2;
  }
  
  #pdi {
    margin-top: 30vh;
    margin-left: 55vw;
    height: 35vh;
    width: 40vh;
    position: absolute;
  }
  
  #pdiTitle {
    margin-top: 0vh;
    margin-left: calc(7px + 1vh + 2vw);
    width: calc(6px + 8vh + 14vw);
    position: absolute;
    z-index: 2;
  }
  
  #hedwig {
    margin-top: 10vh;
    margin-left: 75vw;
    height: 35vh;
    width: 35vh;
    text-align: right;
    position: absolute;
  }
  
  #HwTitle {
    margin-top: calc(4px + 6vh + 7.5vw);
    margin-left: -5vw;
    width: 15vw;
    position: absolute;
    z-index: 2;
  }
  
  #ccd {
    margin-top: 50vh;
    margin-left: 75vw;
    height: 35vh;
    width: 35vh;
    position: absolute;
  }
  
  #RTitle {
    margin-top: 0vh;
    margin-left: 0vw;
    position: absolute;
    z-index: 2;
  }
}

@media only screen and (max-width:749px) {
  .projectsPageWrapper h1 {
    /* color: #008F82; */
    /* darkthemecolor */
    font-size: calc(10px + 1.7vw + 0.3vh);
    font-weight: bold;
  }

  .projectText {
    color: white;
    /* darkthemecolor */
    font-size: calc(10px + 0.7vw + 0.15vh);
    width: calc(22px + 7vh + 3vw);
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .circle {
    /* background: #F1F1F1; */
    /* darkthemecolor */
    border-radius: 50%;
    height: calc(35px + 10vw + 4vh);
    width: calc(35px + 10vw + 4vh);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    transition: background 0.5s ease-in-out;
  }

  #beakspeak {
    margin-top: 15vh;
    margin-left: 5vw;
    height: 15vh;
    width: 15vh;
    position: absolute;
  }

  #carpool {
    margin-top: 35vh;
    margin-left: 5vw;
    height: 15vh;
    width: 15vh;
    text-align: right;
    position: absolute;
  }

  #website {
    margin-top: 55vh;
    margin-left: 5vw;
    height: 15vh;
    width: 15vh;
    text-align: center;
    position: absolute;
  }

  #wTitle {
    margin-top: -5px;
    text-align: center;
  }

  #hedwig {
    margin-top: 5vh;
    margin-left: 50vw;
    height: 35vh;
    width: 40vh;
    position: absolute;
  }

  #HwTitle {
    margin-top: -1vh;
    margin-left: calc(0.5vh + 1vw);
    width: calc(30px + 20vw + 4vh);
    position: absolute;
    z-index: 2;
  }

  #pdi {
    margin-top: 25vh;
    margin-left: 50vw;
    height: 35vh;
    width: 40vh;
    position: absolute;
  }

  #ccd {
    margin-top: 45vh;
    margin-left: 50vw;
    height: 35vh;
    width: 40vh;
    position: absolute;
  }
}

.AboutUsWrapper {
  height:100vh;
  /* background: white; */
  /* darkthemecolor */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background:
  radial-gradient(circle at 5vw 100vh, #FFB9CD,
    #FFB9CD 35vw, transparent 35vw); */
  /* darkthemecolor */
  z-index: 1;
}

#AboutUsParagraph {
  width: 65vw;
  height: 50vh;
  font-family: 'HKGrotesk-Bold-Legacy';
  font-weight: bold;
  font-size: calc(3px + 2.2vw + 0.6vh);
  line-height: 6.3vh;
  letter-spacing: 0.08vw;
  /* color: #787878; */
  /* darkthemecolor */
}
#AboutUsParagraph em {
  font-style: normal;
  /* color: #40BFB4; */
  /* darkthemecolor */
}

Reveal {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@media only screen and (max-width:1200px) {
  #AboutUsParagraph {
    width: 70vw;
    height: 50vh;
    font-family: 'HKGrotesk-Bold-Legacy';
    font-weight: bold;
    font-size: calc(6px + 2.8vw + 0.7vh);
    line-height: 6.3vh;
    letter-spacing: 0.08vw;
    /* color: #787878; */
    /* darkthemecolor */
  }
}

@media only screen and (max-width:749px) {
  #AboutUsParagraph {
    width: 75vw;
    height: 50vh;
    font-family: 'HKGrotesk-Bold-Legacy';
    font-weight: bold;
    font-size: calc(6px + 2.8vw + 0.7vh);
    line-height: 6.3vh;
    letter-spacing: 0.08vw;
    /* color: #787878; */
    /* darkthemecolor */
  }
}

.whatWeDoPageWrapper{
  height: 70vh;
  /* z-index: 1; */
  /* background: white; */
  /* darkthemecolor */
}

.whatWeDoPageWrapper h1{
  width: 80vw;
  color: white;
  font-weight: bold;
  font-size: calc(3px + 1.9vw + 0.5vh);
}

#treeBackground {
  height: 80vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
url(/static/media/RiceApps2022.05a44e42.jpg);


  display: flex;
  align-items: center;
  justify-content: center;

  z-index: -99;

  background-size: 100%;
}


@media only screen and (max-width:1680px) {
  #treeBackground {
    background-attachment: inherit;
    height: 80vh;
  }
}

@media only screen and (max-width:1200px) {
  .whatWeDoPageWrapper{
    height: 55vh;
    /* z-index: 1; */
    /* background: white; */    
    /* darkthemecolor */
  }

  #treeBackground {
    background-attachment: inherit;
    height: 80vh;
  }

  .whatWeDoPageWrapper h1{
    width: 85vw;
    color: white;
    /* darkthemecolor */
    font-weight: bold;
    font-size: calc(5px + 2.3vw + 0.9vh);
  }

}

@media only screen and (max-width:749px) {
  .whatWeDoPageWrapper{
    height: 45vh;
    /* z-index: 1; */
    /* background: white; */
    /* darkthemecolor */
  }

  #treeBackground {
    background-attachment: inherit;
    background-size: 110%;
    height: 35vh;
  }

  .whatWeDoPageWrapper h1{
    width: 90vw;
    color: white;
    font-weight: bold;
    font-size: calc(5px + 2vw + 0.7vh);
  }

}

#logos {
  width: 70vw;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  /* flex-direction: column; */
}

#logosWrapper {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

#image {
  object-fit: contain;
  height: 100%;
  width: 15vw;
  padding: 25px;
  opacity: 0.7;
}

#text {
  display: flex;
  justify-content: flex-start;
  padding-left: 5vw;
  margin-top: 5vh;
  font-size: calc(17px + 1.9vw + 0.4vh);
  /* color: #787878; */
  /* darkthemecolor */
  font-family: 'Questrial', sans-serif;

}

@media only screen and (max-width:1200px) {
  #logos {

    height: 70vh;
    width: 90vw;
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    /* flex-direction: column; */
  }

  #image {
    height: 19vw;
    width: 19vw;
    opacity: 0.7;
    padding-right: 5vh;
    padding-left: 5vh
  }
  #text {
    display: flex;
    justify-content: flex-start;
    padding-left: 5vw;
    margin-top: 5vh;
    font-size: calc(25px + 1.9vw + 0.4vh);
    /* color: #787878; */
    /* darkthemecolor */
    font-family: 'Questrial', sans-serif;

  }
}

@media only screen and (max-width:749px) {
  #logos {

    height: 70vh;
    width: 90vw;
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    /* flex-direction: column; */
  }

  #image {
    height: 25vw;
    width: 25vw;
    opacity: 0.7;
    padding-right: 3vh;
    padding-left: 3vh
  }
  #text {
    display: flex;
    justify-content: flex-start;
    padding-left: 5vw;
    margin-top: 5vh;
    font-size: calc(15px + 1.9vw + 0.4vh);
    /* color: #787878; */
    /* darkthemecolor */
    font-family: 'Questrial', sans-serif;

  }
}


/* Start of menu CSS */

/* .sidebarText:hover, .sidebarTextHome:hover, .sidebarTextContact:hover {
  color: #6E6E6E;
  darkthemecolor
} */

.sidebarText{
  padding-left: 6vw;
  padding-right: 1vw;
  /* darkthemecolor */
}

.sidebarTextHome {
  padding-left: 15vw;
  padding-right: 2vw;
  /* darkthemecolor */
}

.sidebarTextContact {
  padding-left: 5vw;
  padding-right: 1vw;
  /* darkthemecolor */
}

/* HERE'S HOW THE MENU WORKS: */
/* The div is always there, and there's no transformation when the menu is open. */
#menu.open {
  -webkit-transform: none;
          transform: none;
}

#menu.closed{
  visibility: hidden;
}

/* But when the menu is closed, the div is still /displayed/ */
/* It's just that it's translated 10vh off of the screen (which is the height of the menu div) */
#menu {
  width: 100vw;
  height: 10vh;
  /* darkthemecolor */
  align-items: center;
  flex-direction: row;
  display: flex;
  /* justify-content: space-between; */
  font-size: 1.8vw;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: translateY(-10vh);
          transform: translateY(-10vh); /*This is what hides the menu when it's closed*/
  position: fixed;
  z-index: 99;
  /* darkthemecolor */
}

button{
  outline:none;
  position: fixed;
  z-index: 100;
}

#hamburger {
  z-index: 100;
  margin-left: .8vw;
  margin-top: 1.5vh;
}

#dark-switch{
  position: fixed;
  z-index: 100;
  margin-top: 3vh;
  right: 0.8vw;
}

/* End of menu CSS */

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
 .hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible; 
  }

  .hamburger:hover {
      opacity: 0.9; }

  .hamburger.is-active:hover {
      opacity: 0.9; }
  
  
  /* darkthemecolor */

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; 
  }
    
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 40px;
      height: 4px;
      /* darkthemecolor */
      border-radius: 4px;
      position: absolute;
      transition-property: -webkit-transform;
      transition-property: transform;
      transition-property: transform, -webkit-transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }
    
  .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; }
    
  .hamburger-inner::before {
      top: -10px; }
    
  .hamburger-inner::after {
      bottom: -10px; }

  /*
     * 3DX
     */
  .hamburger--3dx .hamburger-box {
    -webkit-perspective: 80px;
            perspective: 80px; }

  .hamburger--3dx .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
      transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

  .hamburger--3dx.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }
    .hamburger--3dx.is-active .hamburger-inner::before {
      -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
              transform: translate3d(0, 10px, 0) rotate(45deg); }
    .hamburger--3dx.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
              transform: translate3d(0, -10px, 0) rotate(-45deg); }

  /*
     * 3DX Reverse
     */
  .hamburger--3dx-r .hamburger-box {
    -webkit-perspective: 80px;
            perspective: 80px; }

  .hamburger--3dx-r .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
    
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
      transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

  .hamburger--3dx-r.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg); }
    
  .hamburger--3dx-r.is-active .hamburger-inner::before {
      -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
              transform: translate3d(0, 10px, 0) rotate(45deg); }
    
  .hamburger--3dx-r.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
              transform: translate3d(0, -10px, 0) rotate(-45deg); }

  /*
     * 3DY
     */
  .hamburger--3dy .hamburger-box {
    -webkit-perspective: 80px;
            perspective: 80px; }

  .hamburger--3dy .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
    .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
      transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

  .hamburger--3dy.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg); }
    
  .hamburger--3dy.is-active .hamburger-inner::before {
      -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
              transform: translate3d(0, 10px, 0) rotate(45deg); }
    
  .hamburger--3dy.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
              transform: translate3d(0, -10px, 0) rotate(-45deg); }

  /*
     * 3DY Reverse
     */
  .hamburger--3dy-r .hamburger-box {
    -webkit-perspective: 80px;
            perspective: 80px; }

  .hamburger--3dy-r .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
    
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
      transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

  .hamburger--3dy-r.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }
    
  .hamburger--3dy-r.is-active .hamburger-inner::before {
      -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
              transform: translate3d(0, 10px, 0) rotate(45deg); }
    
  .hamburger--3dy-r.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
              transform: translate3d(0, -10px, 0) rotate(-45deg); }

  /*
     * 3DXY
     */
  .hamburger--3dxy .hamburger-box {
    -webkit-perspective: 80px;
            perspective: 80px; }

  .hamburger--3dxy .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
    
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
      transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

  .hamburger--3dxy.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateX(180deg) rotateY(180deg);
            transform: rotateX(180deg) rotateY(180deg); }
  
  .hamburger--3dxy.is-active .hamburger-inner::before {
      -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
              transform: translate3d(0, 10px, 0) rotate(45deg); }
    
  .hamburger--3dxy.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
              transform: translate3d(0, -10px, 0) rotate(-45deg); }

  /*
     * 3DXY Reverse
     */
  .hamburger--3dxy-r .hamburger-box {
    -webkit-perspective: 80px;
            perspective: 80px; }

  .hamburger--3dxy-r .hamburger-inner {
    transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
    
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
      transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

  .hamburger--3dxy-r.is-active .hamburger-inner {
    background-color: transparent !important;
    -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
            transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
    
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
      -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
              transform: translate3d(0, 10px, 0) rotate(45deg); }
    
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
              transform: translate3d(0, -10px, 0) rotate(-45deg); }

  /*
     * Arrow
     */
  .hamburger--arrow.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

  .hamburger--arrow.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

  /*
     * Arrow Right
     */
  .hamburger--arrow-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

  .hamburger--arrow-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

  /*
     * Arrow Alt
     */
  .hamburger--arrowalt .hamburger-inner::before {
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

  .hamburger--arrowalt .hamburger-inner::after {
    transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

  .hamburger--arrowalt.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
    transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

  .hamburger--arrowalt.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
    transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

  /*
     * Arrow Alt Right
     */
  .hamburger--arrowalt-r .hamburger-inner::before {
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

  .hamburger--arrowalt-r .hamburger-inner::after {
    transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

  .hamburger--arrowalt-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
    transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

  .hamburger--arrowalt-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
    transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

  /*
     * Arrow Turn
     */
  .hamburger--arrowturn.is-active .hamburger-inner {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
    .hamburger--arrowturn.is-active .hamburger-inner::before {
      -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
              transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
    .hamburger--arrowturn.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
              transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

  /*
     * Arrow Turn Right
     */
  .hamburger--arrowturn-r.is-active .hamburger-inner {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
    .hamburger--arrowturn-r.is-active .hamburger-inner::before {
      -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
              transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
    .hamburger--arrowturn-r.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
              transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

  /*
     * Boring
     */
  .hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
    transition-property: none; }

  .hamburger--boring.is-active .hamburger-inner {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
    .hamburger--boring.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0; }
    .hamburger--boring.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }

  /*
     * Collapse
     */
  .hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--collapse .hamburger-inner::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
    .hamburger--collapse .hamburger-inner::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--collapse.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--collapse.is-active .hamburger-inner::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
    .hamburger--collapse.is-active .hamburger-inner::before {
      top: 0;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

  /*
     * Collapse Reverse
     */
  .hamburger--collapse-r .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--collapse-r .hamburger-inner::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
    .hamburger--collapse-r .hamburger-inner::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--collapse-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
            transform: translate3d(0, -10px, 0) rotate(45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--collapse-r.is-active .hamburger-inner::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
    .hamburger--collapse-r.is-active .hamburger-inner::before {
      top: 0;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

  /*
     * Elastic
     */
  .hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
    .hamburger--elastic .hamburger-inner::before {
      top: 10px;
      transition: opacity 0.125s 0.275s ease; }
    .hamburger--elastic .hamburger-inner::after {
      top: 20px;
      transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

  .hamburger--elastic.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
            transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s; }
    .hamburger--elastic.is-active .hamburger-inner::before {
      transition-delay: 0s;
      opacity: 0; }
    .hamburger--elastic.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
              transform: translate3d(0, -20px, 0) rotate(-270deg);
      transition-delay: 0.075s; }

  /*
     * Elastic Reverse
     */
  .hamburger--elastic-r .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
    .hamburger--elastic-r .hamburger-inner::before {
      top: 10px;
      transition: opacity 0.125s 0.275s ease; }
    .hamburger--elastic-r .hamburger-inner::after {
      top: 20px;
      transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

  .hamburger--elastic-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
            transform: translate3d(0, 10px, 0) rotate(-135deg);
    transition-delay: 0.075s; }
    .hamburger--elastic-r.is-active .hamburger-inner::before {
      transition-delay: 0s;
      opacity: 0; }
    .hamburger--elastic-r.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
              transform: translate3d(0, -20px, 0) rotate(270deg);
      transition-delay: 0.075s; }

  /*
     * Emphatic
     */
  .hamburger--emphatic {
    overflow: hidden; }
    .hamburger--emphatic .hamburger-inner {
      transition: background-color 0.125s 0.175s ease-in; }
      .hamburger--emphatic .hamburger-inner::before {
        left: 0;
        transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
      .hamburger--emphatic .hamburger-inner::after {
        top: 10px;
        right: 0;
        transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic.is-active .hamburger-inner {
      transition-delay: 0s;
      transition-timing-function: ease-out;
      background-color: transparent !important; }
      .hamburger--emphatic.is-active .hamburger-inner::before {
        left: -80px;
        top: -80px;
        -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
                transform: translate3d(80px, 80px, 0) rotate(45deg);
        transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
      .hamburger--emphatic.is-active .hamburger-inner::after {
        right: -80px;
        top: -80px;
        -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
                transform: translate3d(-80px, 80px, 0) rotate(-45deg);
        transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

  /*
     * Emphatic Reverse
     */
  .hamburger--emphatic-r {
    overflow: hidden; }
    .hamburger--emphatic-r .hamburger-inner {
      transition: background-color 0.125s 0.175s ease-in; }
      .hamburger--emphatic-r .hamburger-inner::before {
        left: 0;
        transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
      .hamburger--emphatic-r .hamburger-inner::after {
        top: 10px;
        right: 0;
        transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
        transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic-r.is-active .hamburger-inner {
      transition-delay: 0s;
      transition-timing-function: ease-out;
      background-color: transparent !important; }
      .hamburger--emphatic-r.is-active .hamburger-inner::before {
        left: -80px;
        top: 80px;
        -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
                transform: translate3d(80px, -80px, 0) rotate(-45deg);
        transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
      .hamburger--emphatic-r.is-active .hamburger-inner::after {
        right: -80px;
        top: 80px;
        -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
                transform: translate3d(-80px, -80px, 0) rotate(45deg);
        transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

  /*
     * Minus
     */
  .hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
    transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

  .hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
    opacity: 0;
    transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

  .hamburger--minus.is-active .hamburger-inner::before {
    top: 0; }

  .hamburger--minus.is-active .hamburger-inner::after {
    bottom: 0; }

  /*
     * Slider
     */
  .hamburger--slider .hamburger-inner {
    top: 2px; }
    .hamburger--slider .hamburger-inner::before {
      top: 10px;
      transition-property: opacity, -webkit-transform;
      transition-property: transform, opacity;
      transition-property: transform, opacity, -webkit-transform;
      transition-timing-function: ease;
      transition-duration: 0.15s; }
    .hamburger--slider .hamburger-inner::after {
      top: 20px; }

  .hamburger--slider.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
    .hamburger--slider.is-active .hamburger-inner::before {
      -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
              transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0; }
    .hamburger--slider.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
              transform: translate3d(0, -20px, 0) rotate(-90deg); }

  /*
     * Slider Reverse
     */
  .hamburger--slider-r .hamburger-inner {
    top: 2px; }
    .hamburger--slider-r .hamburger-inner::before {
      top: 10px;
      transition-property: opacity, -webkit-transform;
      transition-property: transform, opacity;
      transition-property: transform, opacity, -webkit-transform;
      transition-timing-function: ease;
      transition-duration: 0.15s; }
    .hamburger--slider-r .hamburger-inner::after {
      top: 20px; }

  .hamburger--slider-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
            transform: translate3d(0, 10px, 0) rotate(-45deg); }
    .hamburger--slider-r.is-active .hamburger-inner::before {
      -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
              transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
      opacity: 0; }
    .hamburger--slider-r.is-active .hamburger-inner::after {
      -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
              transform: translate3d(0, -20px, 0) rotate(90deg); }

  /*
     * Spin
     */
  .hamburger--spin .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--spin .hamburger-inner::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
    .hamburger--spin .hamburger-inner::after {
      transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--spin.is-active .hamburger-inner {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--spin.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
    .hamburger--spin.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

  /*
     * Spin Reverse
     */
  .hamburger--spin-r .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--spin-r .hamburger-inner::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
    .hamburger--spin-r .hamburger-inner::after {
      transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--spin-r.is-active .hamburger-inner {
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--spin-r.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
    .hamburger--spin-r.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

  /*
     * Spring
     */
  .hamburger--spring .hamburger-inner {
    top: 2px;
    transition: background-color 0s 0.13s linear; }
    .hamburger--spring .hamburger-inner::before {
      top: 10px;
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--spring .hamburger-inner::after {
      top: 20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--spring.is-active .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important; }
    .hamburger--spring.is-active .hamburger-inner::before {
      top: 0;
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
              transform: translate3d(0, 10px, 0) rotate(45deg); }
    .hamburger--spring.is-active .hamburger-inner::after {
      top: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
              transform: translate3d(0, 10px, 0) rotate(-45deg); }

  /*
     * Spring Reverse
     */
  .hamburger--spring-r .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--spring-r .hamburger-inner::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
    .hamburger--spring-r .hamburger-inner::before {
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--spring-r.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--spring-r.is-active .hamburger-inner::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
    .hamburger--spring-r.is-active .hamburger-inner::before {
      top: 0;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

  /*
     * Stand
     */
  .hamburger--stand .hamburger-inner {
    transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--stand .hamburger-inner::before {
      transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--stand .hamburger-inner::after {
      transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--stand.is-active .hamburger-inner {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-color: transparent !important;
    transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--stand.is-active .hamburger-inner::before {
      top: 0;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--stand.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

  /*
     * Stand Reverse
     */
  .hamburger--stand-r .hamburger-inner {
    transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--stand-r .hamburger-inner::before {
      transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--stand-r .hamburger-inner::after {
      transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--stand-r.is-active .hamburger-inner {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    background-color: transparent !important;
    transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--stand-r.is-active .hamburger-inner::before {
      top: 0;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--stand-r.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

  /*
     * Squeeze
     */
  .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger--squeeze .hamburger-inner::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
    .hamburger--squeeze .hamburger-inner::after {
      transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

  .hamburger--squeeze.is-active .hamburger-inner {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger--squeeze.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
    .hamburger--squeeze.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

  /*
     * Vortex
     */
  .hamburger--vortex .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
    .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
      transition-duration: 0s;
      transition-delay: 0.1s;
      transition-timing-function: linear; }
    .hamburger--vortex .hamburger-inner::before {
      transition-property: top, opacity; }
    .hamburger--vortex .hamburger-inner::after {
      transition-property: bottom, -webkit-transform;
      transition-property: bottom, transform;
      transition-property: bottom, transform, -webkit-transform; }

  .hamburger--vortex.is-active .hamburger-inner {
    -webkit-transform: rotate(765deg);
            transform: rotate(765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
    .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
      transition-delay: 0s; }
    .hamburger--vortex.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0; }
    .hamburger--vortex.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

  /*
     * Vortex Reverse
     */
  .hamburger--vortex-r .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
    .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
      transition-duration: 0s;
      transition-delay: 0.1s;
      transition-timing-function: linear; }
    .hamburger--vortex-r .hamburger-inner::before {
      transition-property: top, opacity; }
    .hamburger--vortex-r .hamburger-inner::after {
      transition-property: bottom, -webkit-transform;
      transition-property: bottom, transform;
      transition-property: bottom, transform, -webkit-transform; }

  .hamburger--vortex-r.is-active .hamburger-inner {
    -webkit-transform: rotate(-765deg);
            transform: rotate(-765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
    .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
      transition-delay: 0s; }
    .hamburger--vortex-r.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0; }
    .hamburger--vortex-r.is-active .hamburger-inner::after {
      bottom: 0;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg); }

@font-face {
    font-family: 'HKGrotesk-Bold';
    src: url(/static/media/HKGrotesk-Bold.77159e5d.otf);
}

@font-face {
    font-family: 'HKGrotesk-Bold-Legacy';
    src: url(/static/media/HKGrotesk-BoldLegacy.a513317e.otf);
}

@font-face {
    font-family: 'HKGrotesk-Regular';
    src: url(/static/media/HKGrotesk-Regular.eb9ec08a.otf);
}

@font-face {
    font-family: 'HKGrotesk-Light';
    src: url(/static/media/HKGrotesk-Light.646f8450.otf)
}

.top-area {
    display: flex;
    justify-content: space-between;
}

.homeIcon{
  width: 50px;
  height: 50px;
  padding-left: 1vw;
  padding-top: 1.5vh;
}

.dark-switch {
    margin-top: 3vh;
    margin-right: 0.8vw;
}

.pageWrapper {
    height:160vh;
    /* background: white; */
    /* darkthemecolor */
    padding: 2.5vw;

    flex-direction: column;
    align-items: center;
    display: flex;

    /* background: */
    /* circle at [horizontal center], [vertical center], Color,
    Color RADIUS, transparent RADIUS */
    /* radial-gradient(circle at 85vw 60vh, #FFB9CD,
      #FFB9CD 25vw, transparent 25vw); */
      /* darkthemecolor */

    z-index: 1;
  }
#content{
position: relative;
width: 80vw;
text-align: left;
flex-direction: column;
align-items: center;
}
#projectName{
  /* color: #40BFB4; */
  /* darkthemecolor */
  font-size: calc(30px + 3.9vw + 1.8vh);
  font-family: 'HKGrotesk-Bold-Legacy'
}
/* .paragraphs{
    color: #969696;
    darkthemecolor
    GET NEUE HAAS UNICA PRO
} */

#projectDescription{
  width: 50%;
}

#teamAndProjectScope{
  font-size: calc(5px + 1.2vw + 0.4vh);
  padding-top: 5vh;
}
.teamAndProjectScopeLabel{
/* color: #01796F; */
/* darkthemecolor */
padding-bottom: 1vh;
}
#projectScope{
  padding-top: 2vh;
}
.caption{
/* color: #01796F; */
/* darkthemecolor */
font-size: calc(7px + 1.7vw + 0.8vh);
margin-top: 7vh;
font-family: "HKGrotesk-Bold-Legacy"
}
.captionDescription{
margin-top: 4vh;
/* color: #969696; */
/* darkthemecolor */
/* NUE HAAS UNICA PRO */

width: 50vw;
}

@media only screen and (max-width: 1024px) {
    .homeIcon{
        width: 50px;
        height: 50px;
        padding-left: 1vw;
        padding-top: 1.5vh;
    }

    .dark-switch {
        display: inline-block;
        z-index: 100;
        margin-top: 3vh;
    }


    .pageWrapper {
        height:160vh;
        /* background: white; */
        /* darkthemecolor */
        padding: 2.5vw;

        flex-direction: column;
        align-items: center;
        display: flex;

        /* background: */
            /* circle at [horizontal center], [vertical center], Color,
            Color RADIUS, transparent RADIUS */
                /* radial-gradient(circle at 80vw 24vh, #FFB9CD,
                #FFB9CD 30vw, transparent 25vw); */
                /* darkthemecolor */

        z-index: 1;
    }
    #content{
        position: relative;
        width: 80vw;
        text-align: left;
        flex-direction: column;
        align-items: center;
    }
    #projectName{
        /* color: #40BFB4; */
        /* darkthemecolor */
        font-size: calc(20px + 4vw + 1.9vh);
        font-family: 'HKGrotesk-Bold-Legacy'
    }
    .paragraphs{
        /* color: #969696; */
        /* darkthemecolor */
        /* GET NEUE HAAS UNICA PRO */
    }
    #projectDescription{
        width: 70%;
        font-size: calc(7px + 1.8vw + 0.6vh);
    }

    #teamAndProjectScope{
        font-size: calc(10px + 1.4vw + 0.5vh);
        padding-top: 5vh;
    }
    .teamAndProjectScopeLabel{
        /* color: #01796F; */
        /* darkthemecolor */
        padding-bottom: 1vh;
    }
    #projectScope{
        padding-top: 2vh;
    }
    .caption{
        /* color: #01796F; */
        /* darkthemecolor */
        font-size: calc(7px + 1.9vw + 1vh);
        margin-top: 7vh;
        font-family: "HKGrotesk-Bold-Legacy"
    }
    .captionDescription{
        margin-top: 4vh;
        /* color: #969696; */
        /* darkthemecolor */
        /* NUE HAAS UNICA PRO */
        font-size: calc(7px + 1.6vw + 0.8vh);
        width: 75vw;
        margin-bottom: 7vh;
    }
}


@media only screen and (max-width: 749px) {
    #content{
        position: relative;
        width: 87vw;
        text-align: left;
        flex-direction: column;
        align-items: center;
    }
    #projectName{
        /* color: #40BFB4; */
        /* darkthemecolor */
        font-size: calc(27px + 4vw + 1.9vh);
        font-family: 'HKGrotesk-Bold-Legacy'
    }
    .paragraphs{
        /* color: #969696; */
        /* darkthemecolor */
        /* GET NEUE HAAS UNICA PRO */
    }
    #projectDescription{
        width: 80%;
        font-size: calc(7px + 1.8vw + 0.6vh);
    }

    #teamAndProjectScope{
        font-size: calc(10px + 1.4vw + 0.5vh);
        padding-top: 5vh;
    }
    .teamAndProjectScopeLabel{
        /* color: #01796F; */
        /* darkthemecolor */
        padding-bottom: 1vh;
    }
    #projectScope{
        padding-top: 2vh;
    }
    .caption{
        /* color: #01796F; */
        /* darkthemecolor */
        font-size: calc(7px + 2vw + 1.2vh);
        margin-top: 7vh;
        font-family: "HKGrotesk-Bold-Legacy"
    }
    .captionDescription{
        margin-top: 4vh;
        /* color: #969696; */
        /* darkthemecolor */
        /* NUE HAAS UNICA PRO */
        font-size: calc(7px + 1.8vw + 0.8vh);
        width: 78vw;
        margin-bottom: 7vh;
    }
}

