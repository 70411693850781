.top-area {
    display: flex;
    justify-content: space-between;
}

.homeIcon{
  width: 50px;
  height: 50px;
  padding-left: 1vw;
  padding-top: 1.5vh;
}

.dark-switch {
    margin-top: 3vh;
    margin-right: 0.8vw;
}

.pageWrapper {
    height:160vh;
    /* background: white; */
    /* darkthemecolor */
    padding: 2.5vw;

    flex-direction: column;
    align-items: center;
    display: flex;

    /* background: */
    /* circle at [horizontal center], [vertical center], Color,
    Color RADIUS, transparent RADIUS */
    /* radial-gradient(circle at 85vw 60vh, #FFB9CD,
      #FFB9CD 25vw, transparent 25vw); */
      /* darkthemecolor */

    z-index: 1;
  }
#content{
position: relative;
width: 80vw;
text-align: left;
flex-direction: column;
align-items: center;
}
#projectName{
  /* color: #40BFB4; */
  /* darkthemecolor */
  font-size: calc(30px + 3.9vw + 1.8vh);
  font-family: 'HKGrotesk-Bold-Legacy'
}
/* .paragraphs{
    color: #969696;
    darkthemecolor
    GET NEUE HAAS UNICA PRO
} */

#projectDescription{
  width: 50%;
}

#teamAndProjectScope{
  font-size: calc(5px + 1.2vw + 0.4vh);
  padding-top: 5vh;
}
.teamAndProjectScopeLabel{
/* color: #01796F; */
/* darkthemecolor */
padding-bottom: 1vh;
}
#projectScope{
  padding-top: 2vh;
}
.caption{
/* color: #01796F; */
/* darkthemecolor */
font-size: calc(7px + 1.7vw + 0.8vh);
margin-top: 7vh;
font-family: "HKGrotesk-Bold-Legacy"
}
.captionDescription{
margin-top: 4vh;
/* color: #969696; */
/* darkthemecolor */
/* NUE HAAS UNICA PRO */

width: 50vw;
}

@media only screen and (max-width: 1024px) {
    .homeIcon{
        width: 50px;
        height: 50px;
        padding-left: 1vw;
        padding-top: 1.5vh;
    }

    .dark-switch {
        display: inline-block;
        z-index: 100;
        margin-top: 3vh;
    }


    .pageWrapper {
        height:160vh;
        /* background: white; */
        /* darkthemecolor */
        padding: 2.5vw;

        flex-direction: column;
        align-items: center;
        display: flex;

        /* background: */
            /* circle at [horizontal center], [vertical center], Color,
            Color RADIUS, transparent RADIUS */
                /* radial-gradient(circle at 80vw 24vh, #FFB9CD,
                #FFB9CD 30vw, transparent 25vw); */
                /* darkthemecolor */

        z-index: 1;
    }
    #content{
        position: relative;
        width: 80vw;
        text-align: left;
        flex-direction: column;
        align-items: center;
    }
    #projectName{
        /* color: #40BFB4; */
        /* darkthemecolor */
        font-size: calc(20px + 4vw + 1.9vh);
        font-family: 'HKGrotesk-Bold-Legacy'
    }
    .paragraphs{
        /* color: #969696; */
        /* darkthemecolor */
        /* GET NEUE HAAS UNICA PRO */
    }
    #projectDescription{
        width: 70%;
        font-size: calc(7px + 1.8vw + 0.6vh);
    }

    #teamAndProjectScope{
        font-size: calc(10px + 1.4vw + 0.5vh);
        padding-top: 5vh;
    }
    .teamAndProjectScopeLabel{
        /* color: #01796F; */
        /* darkthemecolor */
        padding-bottom: 1vh;
    }
    #projectScope{
        padding-top: 2vh;
    }
    .caption{
        /* color: #01796F; */
        /* darkthemecolor */
        font-size: calc(7px + 1.9vw + 1vh);
        margin-top: 7vh;
        font-family: "HKGrotesk-Bold-Legacy"
    }
    .captionDescription{
        margin-top: 4vh;
        /* color: #969696; */
        /* darkthemecolor */
        /* NUE HAAS UNICA PRO */
        font-size: calc(7px + 1.6vw + 0.8vh);
        width: 75vw;
        margin-bottom: 7vh;
    }
}


@media only screen and (max-width: 749px) {
    #content{
        position: relative;
        width: 87vw;
        text-align: left;
        flex-direction: column;
        align-items: center;
    }
    #projectName{
        /* color: #40BFB4; */
        /* darkthemecolor */
        font-size: calc(27px + 4vw + 1.9vh);
        font-family: 'HKGrotesk-Bold-Legacy'
    }
    .paragraphs{
        /* color: #969696; */
        /* darkthemecolor */
        /* GET NEUE HAAS UNICA PRO */
    }
    #projectDescription{
        width: 80%;
        font-size: calc(7px + 1.8vw + 0.6vh);
    }

    #teamAndProjectScope{
        font-size: calc(10px + 1.4vw + 0.5vh);
        padding-top: 5vh;
    }
    .teamAndProjectScopeLabel{
        /* color: #01796F; */
        /* darkthemecolor */
        padding-bottom: 1vh;
    }
    #projectScope{
        padding-top: 2vh;
    }
    .caption{
        /* color: #01796F; */
        /* darkthemecolor */
        font-size: calc(7px + 2vw + 1.2vh);
        margin-top: 7vh;
        font-family: "HKGrotesk-Bold-Legacy"
    }
    .captionDescription{
        margin-top: 4vh;
        /* color: #969696; */
        /* darkthemecolor */
        /* NUE HAAS UNICA PRO */
        font-size: calc(7px + 1.8vw + 0.8vh);
        width: 78vw;
        margin-bottom: 7vh;
    }
}
