#riceApps{
  position: absolute;
  animation-name: appear;
  animation-delay: 4s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
  font-size: 7.5vw;
  font-family: 'HKGrotesk-Light';
  letter-spacing: 0.2vw;
  /* color: gray; */
  /* darkthemecolor */
}

img{
  width: 28vw;
}

.arrow-open{
  display: flex;
  margin-top: 40vh;

  justify-content: center;
  /* display: inline-block; */
  width: 50%;
  animation-name: appear;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;

  height: 50px;
}
.arrow-closed{
  display: flex;
  justify-content: center;
  visibility: hidden;
  width: 50%;
  margin: 0 auto;
  height: 50px;
}
#arrow{
  cursor: pointer;
}

@keyframes appear {
    from {opacity: 0;}
    to {opacity: 1;}
}

#title {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 100vh;
  /* background-color: white; */
  /* darkthemecolor */
}

.initialText {
  position: absolute;
  animation-name: fade;
  animation-delay: 2s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes fade {
    from {opacity: 1;}
    to {opacity: 0;}
}

#ds{
  display: flex;
  /* color: #40BFB4; */
  /* darkthemecolor */
  width: 100vw;
  align-self: flex-end;
  justify-content: center;
  font-size: 7.5vw;
  font-family: 'HKGrotesk-Regular';
  letter-spacing: 0.2vw;
  font-weight: 50;
}

#gray {
  /* color: gray !important; */
  /* darkthemecolor */
  padding-right: 4vw;
}

#sg {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  font-size: 7.5vw;
  font-family: 'HKGrotesk-Bold';
  letter-spacing: 0.2vw;
  font-weight: bold;
  /* color: #40BFB4; */
  /* darkthemecolor */
}

#social {
  padding-right: 4vw;
}

i {
  /* border: solid black; */
  /* darkthemecolor */
  /* border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px; */
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

#arrow {
  position: relative;
  margin-top: 10vw;
}
