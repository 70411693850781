#content-JoinUs{
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: white; */
  /* darkthemecolor */
  /* background:
  radial-gradient(circle at 77vw calc(100vh - 10vw), #FFB9CD,
    #FFB9CD 30vw, transparent 30vw); */
    /* darkthemecolor */
}

#ContactUsTitle{
  margin-top: 20vh;
  margin-left: 7vw;
  display: flex;
  align-self: flex-end;
  width: 100vw;
  font-size: calc(40px + 5vh + 1.7vw);
  /* color: #40BFB4; */
  /* darkthemecolor */
  font-weight: 1000;
  letter-spacing: 0.3vw;
  font-family: 'HKGrotesk-Bold';
  cursor: pointer;
}


#ContactUsText{
  padding-top: 2vh;
  margin-left: 7vw;
  margin-top: -2.5vh;
  display: flex;
  align-self: flex-start;
  font-weight: bold;
  letter-spacing: 0.12vw;
  /* color: #787878; */
  /* darkthemecolor */
  font-size: calc(5px + 1.6vh + 0.7vw);
  font-family: 'HKGrotesk-Bold';
  margin-bottom: -50vh;
}

#display{
  display: flex;
  width: 100vw;
  align-self: center;
}

@media screen and (max-width: 1024px) {
  #content-JoinUs{
    margin-top: -75vh;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: white; */
    /* darkthemecolor */
    /* background:
      radial-gradient(circle at 77vw calc(100vh - 10vw), #FFB9CD,
      #FFB9CD 40vw, transparent 40vw); */
    /* darkthemecolor */
  }

  #ContactUsTitle{
    margin-top: 70vh;
    margin-left: 7vw;
    display: flex;
    align-self: flex-end;
    width: 100vw;
    font-size: calc(10px + 7vw + 1.3vh);
    /* color: #40BFB4; */
    /* darkthemecolor */
    font-weight: 1000;
    letter-spacing: 0.3vw;
    font-family: 'HKGrotesk-Bold';
    cursor: pointer;
  }

  #ContactUsText{
    padding-top: 2vh;
    margin-left: 7vw;
    margin-top: -2.5vh;
    display: flex;
    align-self: flex-start;
    font-weight: bold;
    letter-spacing: 0.12vw;
    /* color: #787878; */
    /* darkthemecolor */
    font-size: calc(5px + 1.5vh + 0.8vw);
    font-family: 'HKGrotesk-Bold';
    margin-bottom: -50vh;
  }


  #display{
    display: none;
  }
}

@media screen and (max-width: 749px) {
  #ContactUsTitle{
    margin-top: 70vh;
    margin-left: 5vw;
    display: flex;
    align-self: flex-end;
    width: 100vw;
    font-size: calc(10px + 7vw + 1.3vh);
    /* color: #40BFB4; */
    /* darkthemecolor */
    font-weight: 1000;
    letter-spacing: 0.3vw;
    font-family: 'HKGrotesk-Bold';
    cursor: pointer;
  }
  #ContactUsText{

    padding-top: 2vh;
    margin-left: 5vw;
    margin-top: -2.5vh;
    display: flex;
    align-self: flex-start;
    font-weight: bold;
    letter-spacing: 0.12vw;
    /* color: #787878; */
    /* darkthemecolor */
    font-size: calc(3px + 1.5vh + 0.6vw);
    font-family: 'HKGrotesk-Bold';
    margin-bottom: -50vh;
  }
}
