.TestmonialsWrapper {
  height:100vh;
  /* background: white; */
  /* darkthemecolor */
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.CarouselWrapper {
  height:80vh;
  margin-left: 10vw;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.paragraphWrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.carouselBox {
  width: 80vw;
  height: calc(40px + 45vh + 5vw);
}

.TestimonialParagraph {
  position: relative;
  width: 70vw;
  /* height: 16vw; */
  font-family: 'Questrial', sans-serif;
  font-weight: 400;
  font-size: calc(8px + 1.6vw + 0.5vh);
  flex-direction: column;
  align-items: center;
  line-height: 1.2;
  letter-spacing: 0.15vw;
  /* color: #787878; */
  /* darkthemecolor */
  margin-top: 4.5vh;
  text-align: left;
}
.TestimonialParagraph em {
  font-style: normal;
  /* color: #40BFB4; */
  /* darkthemecolor */
}

.testimonialName {
  font-weight: bold;
}


#teamLead{
  margin-left: 1.8vw;
  margin-top: 0.8vh;
}

.nameWrapper{
  position: relative;
  display: flex;
  flex-direction: column;
  /* color: #01796F; */
  /* darkthemecolor */
  font-size: calc(5px + 1.2vw + 0.35vh);
  margin-top: 3vh;
  font-family: sans-serif;
  /* align-items: flex-start;
  justify-content: flex-start; */
}
